import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Col } from 'react-bootstrap'

const TeamsChart = ({ selectedTeams, handleTeamChange, brainStrength, team }) => {
  const [chartData, setChartData] = useState({ options: {}, series: [] })

  useEffect(() => {
    if (team && brainStrength[team]) {
      const metrics = Object.keys(brainStrength[team]).filter((metric) =>
        ['association', 'interest', 'stress', 'focus'].includes(metric)
      )
      const metricValues = metrics.map((metric) => brainStrength[team][metric])

      setChartData({
        series: [
          {
            name: team,
            data: metricValues,
          },
        ],
        options: {
          chart: {
            id: `team-chart-${team}`,
            type: 'bar',
            height: 350,
            zoom: { enabled: false },
            toolbar: { show: false },
          },
          states: { hover: { filter: { type: 'none' } } },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              borderRadius: 8,
              borderRadiusApplication: 'end',
              distributed: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000'],
            },
            formatter: (val) => Math.round(val),
            offsetY: -20,
          },
          legend: { show: false },
          xaxis: { categories: metrics },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 4,
            labels: { formatter: (val) => parseInt(val) },
          },
          colors: ['#05445E', '#189AB4', '#75E6DA', '#D4F1F4'],
          fill: { opacity: 1 },
          tooltip: {
            followCursor: true,
            intersect: false,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              const metricName = w.globals.labels[dataPointIndex]
              const metricValue = series[seriesIndex][dataPointIndex]
              return `<div class="p-1 bg-white">
                        <span class="me-4">${metricName}</span>
                        <span class="fw-bold">${metricValue}</span>
                      </div>`
            },
          },
        },
      })
    }
  }, [team, brainStrength])

  if (!team)
    return (
      <Col xl={4}>
        <select value={team} onChange={handleTeamChange} className='form-select mb-3'>
          <option value=''>Select a Team</option>
          {Object.keys(brainStrength)
            .filter((teamName) => !selectedTeams.includes(teamName))
            .map((teamName) => (
              <option key={teamName} value={teamName}>
                {teamName}
              </option>
            ))}
        </select>
      </Col>
    )

  return (
    <Col xl={4}>
      <select value={team} onChange={handleTeamChange} className='form-select mb-3'>
        <option value=''>Select a Team</option>
        {Object.keys(brainStrength)
          .filter((teamName) => !selectedTeams.includes(teamName)) // Exclude already selected teams
          .map((teamName) => (
            <option key={teamName} value={teamName}>
              {teamName}
            </option>
          ))}
      </select>

      {chartData.series.length > 0 && (
        <ReactApexChart key={team} options={chartData.options} series={chartData.series} type='bar' height={350} />
      )}
    </Col>
  )
}

export default TeamsChart
