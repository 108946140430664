import { format, subMonths } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import { Doughnut } from 'react-chartjs-2'
import * as API from '../Apisurl'
import Context from '../Context'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import TeamsChart from './TeamChart'

const OverviewCorp = () => {
  const isGroup = localStorage.getItem('isGroup')
  const { selectValue } = useContext(Context)

  const [lp, setLp] = useState()
  const [ms, setMs] = useState()
  const [brainStrength, setBrainStrength] = useState(null)
  const [userAnalysis, setUserAnalysis] = useState()

  const [specialities, setSpecialities] = useState([])

  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(true)

  const [selectedTeams, setSelectedTeams] = useState(['', '', ''])

  const [filter, setFilter] = useState({
    gender: 'all',
    startDate: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  })

  let role = localStorage.getItem('role')

  useEffect(() => {
    const getSpecialities = async () => {
      setLoading1(true)
      try {
        const response = await fetch(`${API.Fetchurl}get_all_speciality`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
        })

        const data = await response.json()
        setSpecialities(data.map((d) => d.toLowerCase()))
        setLoading1(false)
      } catch (error) {
        console.log(error)
      }
    }
    getSpecialities()
  }, [])

  useEffect(() => {
    const getDash = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}corporate_dash`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
          body: JSON.stringify({
            id: selectValue.user_id,
            isGroup: isGroup,
          }),
        })

        const data = await response.json()
        setLp(data)
      } catch (error) {
        console.log(error)
      }
    }
    getDash()
  }, [selectValue.user_id, isGroup])

  useEffect(() => {
    const getinfo = async () => {
      setLoading(true)

      let sdate = filter.startDate.split('-')
      let edate = filter.endDate.split('-')

      let s_y = sdate[0],
        s_m = sdate[1]
      let e_y = edate[0],
        e_m = edate[1]

      try {
        const response = await fetch(
          `${API.Fetchurl}overview_analysis?isGroup=${isGroup}&id=${selectValue.user_id}&is_gender=${filter.gender}&start_year=${s_y}&start_month=${s_m}&end_year=${e_y}&end_month=${e_m}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('session')}`,
            },
          }
        )
        if (!response.ok) throw new Error('Something Went Wrong...')

        const data = await response.json()

        setMs({
          Fatigue: data.average.difference.tired,
          Happiness: data.average.difference.happy,
        })

        const filteredTeams = Object.entries(data.average).reduce((acc, [teamName, teamData]) => {
          if (specialities.includes(teamName.toLowerCase()) && Object.keys(teamData).length) {
            acc[teamName] = teamData
          }
          return acc
        }, {})

        setBrainStrength(filteredTeams)
        setSelectedTeams([...Object.keys(filteredTeams).slice(0, 3), '', '', ''].slice(0, 3))

        setUserAnalysis([
          data.attempted_analysis.gender_dis.all,
          data.attempted_analysis.gender_dis.M,
          data.attempted_analysis.gender_dis.F,
          data.attempted_analysis.gender_dis.unattempt,
        ])

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    !loading1 && getinfo()
  }, [selectValue.user_id, isGroup, filter.gender, filter.startDate, filter.endDate, loading1])

  const doughnutLabel = {
    id: 'doughnutLabel',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart
      ctx.save()

      const xCoor = chart.getDatasetMeta(0).data[0].x
      const yCoor = chart.getDatasetMeta(0).data[0].y
      ctx.font = 'bold 30px sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${Math.abs(data.datasets[0].data[0])}%`, xCoor, yCoor)
    },
  }

  const handleTeamChange = (e, i) => {
    const { value } = e.target
    const updatedTeams = [...selectedTeams]
    updatedTeams[i] = value
    setSelectedTeams(updatedTeams)
  }

  return (
    <div className='content flex-grow-1 text-black'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Hi there</h2>
            <p>Here’s a quick overview for your reports</p>
          </div>
          <Activity />
        </div>
        {isGroup === 'true' && <Header />}

        <div className='dashboard-cards d-flex flex-column gap-4'>
          <div className='d-flex flex-wrap gap-3'>
            <FloatingLabel controlId='gender' label='Gender'>
              <Form.Select
                required
                type='select'
                value={filter.gender}
                onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
                className='text-capitalize'
              >
                {['all', 'male', 'female'].map((gender, i) => (
                  <option key={i} value={gender}>
                    {gender.replaceAll('_', ' ')}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId='start' label='Start Date'>
              <Form.Control
                required
                type='date'
                value={filter.startDate}
                onChange={(e) => setFilter({ ...filter, startDate: e.target.value })}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId='end' label='End Date'>
              <Form.Control
                required
                type='date'
                value={filter.endDate}
                onChange={(e) => setFilter({ ...filter, endDate: e.target.value })}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
          </div>

          {!loading && (
            <>
              {/* --------------------- Row 1 --------------------------------------------  */}
              <div className='d-flex flex-wrap gap-4 justify-content-around align-items-center'>
                <div>
                  <div
                    className='rounded-pill px-3 py-2'
                    style={{
                      backgroundColor: 'var(--primary-color)',
                      margin: '12px auto',
                      width: 'fit-content',
                    }}
                  >
                    <h4 className='m-0 text-white text-center'>Hiring/Rehiring</h4>
                  </div>

                  <div className='d-flex flex-wrap gap-4'>
                    {lp &&
                      Object.entries(lp)
                        .reverse()
                        .map(([k, v], i) => {
                          if ((role !== 'hr' || role !== 'admin') && k === 'attrition') return

                          let info = {
                            attrition: `Have a ${v > 0 ? 'high' : 'low'} tendency to switch`,
                            burnout: `Are on ${v > 0 ? 'higher' : 'lower'} than average burnout`,
                            productivity: `Reported ${v > 0 ? 'healthy' : 'lower than average'} productivity level`,
                          }
                          let data = getDonutData(k, v)

                          return (
                            <div key={i} className='mx-auto'>
                              <h4
                                className='text-capitalize text-center opacity-100 text-black m-0 my-2'
                                style={{ letterSpacing: 'revert' }}
                              >
                                {k}
                              </h4>
                              <div>
                                <Doughnut
                                  data={data}
                                  height='150px'
                                  width='150px'
                                  plugins={[doughnutLabel]}
                                  options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                      tooltip: {
                                        enabled: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                              <p className='m-0 my-2'>{info[k]}</p>
                            </div>
                          )
                        })}
                  </div>
                </div>
                <div>
                  <div
                    className='rounded-pill px-3 py-2'
                    style={{
                      backgroundColor: 'var(--primary-color)',
                      margin: '12px auto',
                      width: 'fit-content',
                    }}
                  >
                    <h4 className='m-0 text-white text-center'>Improvements</h4>
                  </div>

                  <div className='d-flex flex-wrap gap-4'>
                    {Object.entries(ms).map(([k, v], i) => {
                      let color
                      if (v >= 0) {
                        color = k === 'Fatigue' ? '#be5f5a' : '#15a48f'
                      } else {
                        color = k === 'Fatigue' ? '#15a48f' : '#be5f5a'
                      }
                      return (
                        <div key={i} className='mx-auto'>
                          <h4
                            className='text-capitalize text-center opacity-100 text-black m-0 my-2'
                            style={{ letterSpacing: 'revert' }}
                          >
                            {k}
                          </h4>
                          <div>
                            <Doughnut
                              data={{
                                datasets: [
                                  {
                                    data: [v, 100 - v],
                                    backgroundColor: [color, '#D9D9D9'],
                                    borderRadius: 4,
                                    borderWidth: 0,
                                    cutout: '60%',
                                  },
                                ],
                              }}
                              height='150px'
                              width='150px'
                              plugins={[doughnutLabel]}
                              options={{
                                maintainAspectRatio: false,
                                plugins: {
                                  tooltip: {
                                    enabled: false,
                                  },
                                },
                              }}
                            />
                          </div>
                          <p className='m-0 my-2'>
                            {k} {v >= 0 ? 'increased' : 'decreased'} by {Math.abs(v)}%
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {/* --------------------- Row 2 --------------------------------------------  */}

              <div
                className='rounded-pill px-3 py-2'
                style={{
                  backgroundColor: 'var(--primary-color)',
                  margin: '12px auto',
                  width: 'fit-content',
                }}
              >
                <h4 className='m-0 text-white text-center'>Team Strengths</h4>
              </div>
              <Row>
                {selectedTeams.map((team, i) => (
                  <TeamsChart
                    key={i}
                    selectedTeams={selectedTeams.filter((teamName) => teamName !== team)}
                    handleTeamChange={(e) => handleTeamChange(e, i)}
                    brainStrength={brainStrength}
                    team={team}
                  />
                ))}
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default OverviewCorp

const getColor = (val) => {
  return val <= 30 ? '#be5f5a' : val <= 60 ? '#BDA25C' : '#15a48f'
}

const getDonutData = (key, value) => {
  let color = key === 'burnout' ? '#be5f5a' : key === 'attrition' ? getColor(value) : '#15a48f'

  return {
    datasets: [
      {
        data: [Math.round(value), 100 - value],
        backgroundColor: [color, '#D9D9D9'],
        borderRadius: 4,
        borderWidth: 0,
        cutout: '60%',
      },
    ],
  }
}

// const getBarData = (data) => {
//   let labels = Object.keys(data).map((l) => l.at(0).toUpperCase() + l.slice(1))
//   let d = Object.values(data).map(({ association, interest, focus, stress }) => {
//     return [association, interest, focus, stress]
//   })

//   return {
//     labels: labels,
//     datasets: [
//       {
//         label: 'Association',
//         data: d.map((val) => val[0]),
//         backgroundColor: '#2b8bba',
//         borderRadius: 8,
//         minBarLength: 2,
//         maxBarThickness: 50,
//       },
//       {
//         label: 'Interactive',
//         data: d.map((val) => val[1]),
//         backgroundColor: '#40b9d6',
//         borderRadius: 8,
//         minBarLength: 2,
//         maxBarThickness: 50,
//       },
//       {
//         label: 'Focus',
//         data: d.map((val) => val[2]),
//         backgroundColor: '#7dc9bd',
//         borderRadius: 8,
//         minBarLength: 2,
//         maxBarThickness: 50,
//       },
//       {
//         label: 'Stress',
//         data: d.map((val) => val[3]),
//         backgroundColor: '#19a393',
//         borderRadius: 8,
//         minBarLength: 2,
//         maxBarThickness: 50,
//       },
//     ],
//   }
// }

// const options = {
//   plugins: {
//     tooltip: {
//       displayColors: false,
//     },
//   },

//   scales: {
//     x: {
//       stacked: true,
//       grid: {
//         display: false,
//       },
//     },
//     y: {
//       stacked: true,
//       min: 0,
//       ticks: {
//         stepSize: 25,
//         callback: (value) => {
//           return value + '%'
//         },
//       },
//     },
//   },
// }
