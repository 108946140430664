import LogRocket from 'logrocket'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useStopwatch } from 'react-timer-hook'
import AccessByroleRoutes from './AccessByroleRoutes'
import AdminAccess from './AdminAccess'
import './App.css'
import SelectAssign from './Assignment/SelectAssign'
import SelectCategoryallot from './Assignment/SelectCategoryallot'
import Context from './Context'
import NotFound from './NotFound'
import Personalised from './Personalised'
import { PrivateRoute } from './ProtectedRoutes'
import SchoolAccess from './SchoolAccess'
import Selectedlang from './Selectedlang'
import Admin from './admin/Admin'
import Add from './admin/pages/Add'
import Edit from './admin/pages/Edit'
import Shop from './admin/pages/Shop'
import Users from './admin/pages/Users'
import Aggregate from './aggregate/Aggregate'
import Brain from './brain/Brain'
import ChildrenForm from './children/Children'
import SelectChild from './children/SelectChild'
import SelectGroup from './children/SelectGroup'
import SelectionType from './children/SelectionType'
import Activities from './corporate/Activities'
import Corsi from './corporate/games/Corsi'
import LeftRight from './corporate/games/LeftRight'
import MemoryGame from './corporate/games/MemoryGame'
import MemorySpan from './corporate/games/MemorySpan'
import MentalMaths from './corporate/games/MentalMaths'
import Rocket from './corporate/games/Rocket'
import Rocket2 from './corporate/games/Rocket2'
import Schutle from './corporate/games/Schutle'
import BrainReports from './corporate/reports/BrainReports'
import Games from './corporate/reports/Games'
import QRCReports from './corporate/reports/QRCReports'
import Questionarie from './corporate/reports/Questionarie'
import QuestionarieLogical from './corporate/reports/QuestionarieLogical'
import * as AllDashboardData from './dashboard/AllDashboardData'
import DashboardLayout from './dashboard/Dashboard'
import Dashboard from './dashboard/Main'
import Success from './includes/FormSuccess'
import Insights from './insights/Insights'
import ForgotPassword from './login/ForgotPassword'
import LoginMain from './login/LoginMain'
import LoginType from './login/LoginType'
import Renew from './login/Renew'
import Meet from './meet/Meet'
import Overview from './overview/Overview'
import OverviewCorp from './overview/OverviewCorp'
import PersonalityInsights from './profile/PersonalityInsights'
import QRC from './questionarie/QRC'
import QRCResult from './questionarie/QRCResult'
import Questions from './questionarie/Questions'
import QuestionsResult from './questionarie/QuestionsResult'
import ActivityDetails from './recommendation-system/ActivityDetails'
import RecommendationLayout from './recommendation-system/Layout'
import RecommendationSystem from './recommendation-system/RecommendationSystem'
import ReportTable from './report/Allreport'
import AssignmentReport from './report/AssignmentReport'
import Brain_report from './report/Brain_report'
import Report from './report/Report'
import SchoolReport from './report/SchoolReport'
import Role from './role/Role'
import SchoolCategory from './school/Category'
import Language from './school/Language'
import SchoolLayout from './school/MainSelect'
import ModuleCard from './school/ModuleCard'
import PhaseSelect from './school/PhaseSelect'
import Resultdashboard from './school/ResultOverview'
import Communication from './school/communication/Communication'
import Education from './school/education/Education'
import Etable from './school/education/Etable'
import GameCatSlider from './school/gamemodules/GameCatSlider'
import Level from './school/gamemodules/Level'
import GameMainComponent from './school/gamemodules/MainComponent'
import QuizLayout from './school/gamemodules/QuizLayout'
import Reportlayout from './school/gamemodules/Reportlayout'
import ResultLayout from './school/gamemodules/ResultLayout'
import ColorTable from './school/gamemodules/color/ColorTable'
import ColorGame from './school/gamemodules/color/Colorgame'
import TracingGame from './school/gamemodules/drawing/Canvas'
import Drawresult from './school/gamemodules/drawing/Drawresult'
import Direction from './school/gamemodules/english/Directiongame'
import EnglishTable from './school/gamemodules/english/EnglishTable'
import EnglishGame from './school/gamemodules/english/Englishgame'
import SentanceTable from './school/gamemodules/english/SentanceTable'
import Evs from './school/gamemodules/evs/Evs'
import EvsTable from './school/gamemodules/evs/EvsTable'
import Action from './school/gamemodules/gesture/Actions'
import Gifs from './school/gamemodules/gesture/Gif'
import Matching from './school/gamemodules/match/MatchGame'
import MatchResult from './school/gamemodules/match/Matchresult'
import MathsGame from './school/gamemodules/maths/Mathgame'
import MathsTable from './school/gamemodules/maths/Mathresult'
import Money from './school/gamemodules/money/MoneyGame'
import MoneyResult from './school/gamemodules/money/MoneyResult'
import TimeResult from './school/gamemodules/time/TimeResult'
import Time from './school/gamemodules/time/Timegame'
import Qrcodepay from './school/gamemodules/vocational/Qrcodepay'
import VocationResult from './school/gamemodules/vocational/VocationResult'
import VocationalGame from './school/gamemodules/vocational/VocationalGame'
import QuizGame from './school/quiz/QuizGame'
import QuizResult from './school/quiz/QuizResult'
import Stream from './stream/VideoRecorder'
import SubjectInsights from './subject/SubjectInsights'
import FormComponent from './teacher/Form'
import Team from './team/Team'
import Sessions from './weekplan/Sessions'
import WeekPlan from './weekplan/WeekPlan'

function App() {
  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useStopwatch({ autoStart: false })
  const [timeLeft, setTimeLeft] = useState(false)
  const [videoURL, setVideoURL] = useState()
  const [screenURL, setScreenURL] = useState()
  const [selectValue, setSelectedValue1] = useState('')
  const [bloomData, setbloomData] = useState('')
  const [pieData, setpieData] = useState('')
  const [emotData, setemotData] = useState('')
  const [pm, setpmData] = useState('')
  const [reportTable, setreportTable] = useState('')
  const [PersonalisedData, setPersonalisedData] = useState('')
  const [moduleCardjson, setmoduleCardjson] = useState([])
  const [selectedModule, setselectedModule] = useState('Module 1')
  const [userServices, setUserServices] = useState(
    sessionStorage.getItem('services') ? JSON.parse(sessionStorage.getItem('services')) : null
  )

  let role = localStorage.getItem('role')

  let Bloomfn = AllDashboardData.BloomData
  let piefn = AllDashboardData.populatePieC
  let emotfn = AllDashboardData.emotData
  let performanceFn = AllDashboardData.performManceMatricsOverview
  let reportTablefn = AllDashboardData.changeUserTable
  let perosnalizedfn = AllDashboardData.changePersonalized

  let pathName = useLocation()

  Selectedlang()
  let arr = pathName.pathname.toString().split('/')
  let gameUrl = '/school/:module/games/:play/:cat/'

  const gameRoutes = () => {
    let games
    if (pathName.state != null || pathName.state != undefined) {
      if (arr[4] != undefined) {
        switch (arr[4].toLowerCase()) {
          case 'maths':
            games = { game: <MathsGame />, gameResult: <MathsTable /> }
            break

          case 'english':
            games = { game: <EnglishGame />, gameResult: <EnglishTable /> }
            break

          case 'colors':
            games = { game: <ColorGame />, gameResult: <ColorTable /> }
            break

          case 'match':
            games = { game: <Matching />, gameResult: <MatchResult /> }
            break

          case 'direction':
            games = { game: <Direction />, gameResult: <EnglishTable /> }
            break

          case 'money':
            games = { game: <Money />, gameResult: <MoneyResult /> }
            break

          case 'time':
            games = { game: <Time />, gameResult: <TimeResult /> }
            break

          case 'drawing':
            games = { game: <TracingGame />, gameResult: <Drawresult /> }
            break

          case 'evs':
            games = { game: <Evs />, gameResult: <EvsTable /> }
            break

          case 'typing':
            games = { game: <VocationalGame />, gameResult: <VocationResult /> }
            break

          default:
            games = { game: <TracingGame />, gameResult: <Drawresult /> }
            break
        }
      }
    }
    return games
  }

  let publicRoutes = [
    // { path: '/welcome', element: <Welcome /> },
    { path: '/loginmain', element: <LoginMain /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/renew', element: <Renew /> },

    { path: '/role/school', element: <Role /> },
    { path: '/role/corporate', element: <Role /> },
    { path: '/role/institution', element: <Role /> },
    { path: '/role/:type', element: <FormComponent /> },

    { path: '/add', element: <SelectionType /> },
    { path: '/children', element: <ChildrenForm /> },
    { path: '/children/add', element: <SelectChild /> },
    { path: '/children/add/group', element: <SelectGroup /> },
    { path: '/children/add/success', element: <Success /> },
    { path: '/logintype', element: <LoginType /> },
    { path: '/Language', element: <Language /> },
    { path: '/qrCodePay', element: <Qrcodepay /> },
  ]

  let privateRoute = [
    {
      path: '/',
      element: sessionStorage.getItem('session') == 'be9d991a-e5d5-4142-81ba-c79d1fabdf07' ? <Stream /> : <Dashboard />,
    },

    { path: '/report', element: <Report title='report' /> },
    { path: '/report/all', element: <ReportTable title='report' /> },

    { path: '/report/schoolreport', element: <SchoolReport title='report' /> },
    { path: '/report/assignmentreport', element: <AssignmentReport title='report' /> },
    { path: '/personalised', element: <Personalised /> },
    { path: '/stream', element: <Stream /> },
  ]

  let adminRoute = [
    { path: '/admin', element: <Admin /> },
    { path: '/admin/add', element: <Add /> },
    { path: '/admin/edit', element: <Edit /> },
    { path: '/admin/shop', element: <Shop /> },
    { path: '/admin/:role', element: <Users /> },
  ]

  let corporateRoute = [
    { path: '/brain_report', element: <Brain_report title='report' /> },
    { path: '/overview', element: role === 'teacher' || role === 'parent' ? <Overview /> : <OverviewCorp /> },

    { path: '/profile', element: <PersonalityInsights /> },
    { path: '/subject', element: <SubjectInsights /> },
    { path: '/aggregate', element: <Aggregate /> },
    { path: '/insights', element: <Insights /> },
    { path: '/team', element: <Team /> },

    { path: '/brain', element: <Brain /> },
    { path: '/brainreport', element: <BrainReports /> },

    { path: '/activities', element: <Activities /> },

    { path: '/weekplan', element: <WeekPlan /> },
    { path: '/weekplan/:week', element: <Sessions /> },

    { path: '/games/rocket', element: <Rocket /> },
    { path: '/games/rocket2', element: <Rocket2 /> },
    { path: '/games/leftright', element: <LeftRight /> },
    { path: '/games/memorygame', element: <MemoryGame /> },
    // { path: '/games/puzzle', element: <Puzzle15 /> },
    { path: '/games/schutle', element: <Schutle /> },
    { path: '/games/mentalmaths', element: <MentalMaths /> },
    { path: '/games/corsi', element: <Corsi /> },
    { path: '/games/memoryspan', element: <MemorySpan /> },

    { path: '/keirsey', element: <QRC /> },
    { path: '/keirsey/result', element: <QRCResult /> },
    { path: '/questionarie', element: <Questions /> },
    { path: '/questionarie/:result', element: <QuestionsResult /> },

    { path: '/viewreport/games', element: <Games /> },
    { path: '/viewreport/questionarie', element: <Questionarie /> },
    { path: '/viewreport/questionarielogical', element: <QuestionarieLogical /> },
    { path: '/viewreport/keirsey', element: <QRCReports /> },
  ]

  let gameR = gameRoutes() != undefined ? gameRoutes().game : ''
  let gameResult = gameRoutes() != undefined ? gameRoutes().gameResult : ''

  let schoolLayout = [
    { path: '/school/:module/education/:category/ereport', element: <Reportlayout /> },
    { path: '/report/education', element: <Reportlayout /> },
    { path: '/Assignment/SelectChildren', element: <SelectAssign /> },
    { path: '/Assignment/SelectCategory', element: <SelectCategoryallot /> },
    { path: '/school/', element: <PhaseSelect /> },
    { path: '/school/:module/', element: <ModuleCard /> },
    {
      path: arr[3] == 'games' ? '/school/:module/games/' : '/school/:module/:modulename/',
      element: arr[3] == 'games' || arr[3] === 'quizzes' ? <GameCatSlider /> : <SchoolCategory />,
    },
    { path: '/school/:module/quizzes/:category', element: <QuizGame /> },

    { path: '/school/:module/education/:category', element: <Education /> },
    { path: '/school/:module/flashcard/:category', element: <Communication /> },
    { path: '/school/:module/games/:play', element: <GameMainComponent /> },
    { path: '/school/:module/games/typing/:cat/:level', element: <VocationalGame /> },
    { path: '/school/:module/games/level/direction', element: <Direction /> },

    { path: '' + gameUrl + ':level', element: gameR },
    { path: '' + gameUrl + '', element: <Level /> },
  ]

  let quizLayout = [
    { path: '/school/:module/quizzes/:category/report', element: <QuizResult /> },
    { path: '/report/quizzes', element: <QuizResult /> },
  ]

  let resultLayout = [
    { path: '/report/flashcard', element: <Etable /> },
    { path: '/report/english', element: <EnglishTable /> },
    { path: '/report/colors', element: <ColorTable /> },
    { path: '/report/maths', element: <MathsTable /> },
    { path: '/report/typing', element: <VocationResult /> },
    { path: '/report/match', element: <MatchResult /> },
    { path: '/report/evs', element: <EvsTable /> },

    { path: '/school/:module/flashcard/:category/report', element: <Etable /> },
    { path: '' + gameUrl + ':level/:mathresult', element: gameResult },
    { path: '' + gameUrl + ':level/senresult', element: <SentanceTable /> },
  ]

  let notfound = [{ path: '*', element: <NotFound /> }]

  useEffect(() => {
    LogRocket.init('syllse/nemaai')
  }, [])

  return (
    <>
      <Context.Provider
        value={{
          userServices,
          setUserServices,
          videoURL,
          setVideoURL,
          screenURL,
          setScreenURL,
          timeLeft,
          setTimeLeft,
          selectValue,
          setSelectedValue1,
          Bloomfn,
          pause,
          resume,
          restart,
          piefn,
          start,
          emotfn,
          bloomData,
          setbloomData,
          pieData,
          setpieData,
          emotData,
          setemotData,
          pm,
          setpmData,
          performanceFn,
          reportTablefn,
          reportTable,
          setreportTable,
          setPersonalisedData,
          perosnalizedfn,
          moduleCardjson,
          setmoduleCardjson,
          selectedModule,
          setselectedModule,
        }}
      >
        <>
          {isRunning ? (
            <div className='profile-container profile-item timer-popup'>
              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>
          ) : (
            ''
          )}
          <Routes>
            {publicRoutes.map((obj, i) => {
              return <Route key={i} path={obj.path} element={obj.element} />
            })}

            <Route element={<PrivateRoute />}>
              <Route element={<DashboardLayout />}>
                <Route element={<SchoolAccess />}>
                  {privateRoute.map((obj, i) => {
                    return <Route key={i} path={obj.path} element={obj.element} />
                  })}
                </Route>

                <Route element={<AdminAccess />}>
                  {adminRoute.map((obj, i) => {
                    return <Route key={i} path={obj.path} element={obj.element} />
                  })}
                </Route>

                {corporateRoute.map((obj, i) => {
                  return <Route key={i} path={obj.path} element={obj.element} />
                })}

                <Route path='/recommendations' element={<RecommendationLayout />}>
                  <Route index element={<RecommendationSystem />} />
                  <Route path=':activityName' element={<ActivityDetails />} />
                </Route>

                <Route element={<AccessByroleRoutes />}>
                  <Route element={<SchoolLayout />}>
                    {schoolLayout.map((obj, i) => {
                      return <Route key={i} path={obj.path} element={obj.element} />
                    })}

                    <Route element={<QuizLayout />}>
                      {quizLayout.map((obj, i) => {
                        return <Route key={i} path={obj.path} element={obj.element} />
                      })}
                    </Route>

                    <Route element={<ResultLayout />}>
                      {resultLayout.map((obj, i) => {
                        return <Route key={i} path={obj.path} element={obj.element} />
                      })}
                    </Route>
                    <Route path='/school/games/level/gifs' element={<Gifs />} />
                    <Route path='/school/games/level/action' element={<Action />} />
                  </Route>
                </Route>
                <Route path='/meet' element={<Meet />} />
                <Route path='/result' element={<Resultdashboard />} />
              </Route>
            </Route>
            {notfound.map((obj, i) => {
              return <Route key={i} path={obj.path} element={obj.element} />
            })}
          </Routes>
          <div className='float-widget d-print-none'>
            <a
              href='https://api.whatsapp.com/send?phone=8744923572&lang=eng&text=Hi, Welcome to NEMA AI. Please tell us your query, our customer support team will reach out to you in 24 hours.'
              className='floaty'
              target='_blank'
            >
              <div className='whatsappFloat'>
                <svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'>
                  <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z' />
                </svg>
              </div>
            </a>
          </div>
        </>
      </Context.Provider>
    </>
  )
}

export default App
